// @ts-check

const en = {
  rulerAcronym: 'RULER',
  rulerTitle: 'RULER Online',
  yceiAcronym: 'YCEI',
  courses: 'Courses',
  coaching: 'Coaching',
  resources: 'Resources',
  tools: 'Tools',
  reports: 'Reports',
  journal: 'Journal',
  rulerize: 'RULERize',
  theCharter: 'The Charter',
  charter: 'Charter',
  theMoodMeter: 'The Mood Meter',
  theMetaMoment: 'The Meta-Moment',
  theBlueprint: 'The Blueprint',
  moodMeter: 'Mood Meter',
  settings: 'Personal Profile',
  logOut: 'Logout',
  edit: 'Edit',
  move: 'Move',
  view: 'View',
  remove: 'Remove',
  delete: 'Delete',
  phone: 'Phone',
  menu: 'Menu',
  overview: 'Overview',
  uploadButton: 'Upload',
  saveButton: 'Save',
  cancelButton: 'Cancel',
  closeButton: 'Close',
  submitButton: 'Submit',
  okButton: 'Ok',
  skipButton: 'Skip',
  done: 'Done',
  registrationCode: 'Registration Code',
  email: 'Email',
  optional: 'Optional',
  welcome: 'Welcome',
  all: 'All',
  duration: 'Approx. Time',
  start: 'Start',
  continue: 'Continue',
  finish: 'Finish',
  backTo: 'Back to',
  districtProfiles: 'District Profiles',
  school: 'School',
  district: 'District',
  schoolProfiles: 'School Profiles',
  schoolProfile: 'School Profile',
  districtProfile: 'District Profile',
  statistics: 'Statistics',
  tos: 'Terms of Use',
  help: 'Help',
  status: 'Status',
  privacy: 'Privacy Statement',
  aboutWebsites: 'Yale Center for Emotional Intelligence RULER Program Websites',
  editProfile: 'Edit Profile',
  feedbackBtn: 'Give us feedback',
  skipToContent: 'Skip to content',
  comingSoon: 'Coming Soon',
  fullName: 'Full Name',
  active: 'Active',
  closed: 'Closed',
  noResultsFound: 'No results found',
  hi: 'Hi, {0}',
  footer: {
    allRightsReserved: 'All Rights Reserved',
    contact: 'Contact',
  },
  yes: 'yes',
  no: 'no',
  at: 'at',
  grade: 'Grade',
  hours: 'hours',
  minutes: 'minutes',
  begin: 'Begin',
  retake: 'Retake',
  message: 'Message',
  module: 'Module',
  rememberMe: 'Remember Me',
  schools: 'Schools',
  nextUp: 'Next Up...',
  copy: 'Copy',
  copied: 'Copied',
  year: 'Year',
  none: 'None',
  search: 'Search',
  searchUsers: 'Search Users',
  searchBySchoolName: 'Search Schools',
  searchByDistrictName: 'Search Districts',
  energy: 'Energy',
  pleasantness: 'Pleasantness',
  glossaryCard: {
    viewMore: 'View More',
    closeTidbit: 'Close Tidbit',
  },
  thankYou: 'Thank you!',
  onboardingUserStatus: {
    enabled: 'Active',
    onboarding: 'Onboarding',
    invited: 'Invited',
  },
  dateFilter: {
    orSelectDateRange: 'Or select date range',
    fromDate: 'From date',
    toDate: 'To date',
    openDatepicker: 'Open datepicker',
  },
  orderFilter: {
    latestToEarliest: 'Latest to Earliest',
    earliestToLatest: 'Earliest to Latest',
    nameAsc: 'Name A - Z',
    nameDesc: 'Name Z - A',
    dateAsc: 'Least recent',
    dateDesc: 'Most recent',
  },
  notifications: {
    title: 'Notifications',
    markAllAsRead: 'Mark as Read',
    seeAll: 'See all',
    noNotifications: 'No new notifications',
    events: {
      person: {
        insert_self_signup: '<strong>{0}</strong> has joined the community.',
      },
      school: {
        insert: 'School <strong>{0}</strong> has been created successfully.',
      },
      district: {
        insert: 'District <strong>{0}</strong> has been created successfully.',
      },
      staff_charter: {
        insert: "Your school's staff charter was updated.",
      },
      staff_charter_survey: {
        insert: 'Staff charter survey has been published.',
        closed: 'Staff charter survey has been closed.',
        duration: 'Duration: ',
      },
      mood_meter_group: {
        insert: 'Mood Meter Group <strong>{0}</strong> has been created.',
        closed: 'Mood Meter Group <strong>{0}</strong> has been closed.',
        viewResults: 'View results',
      },
      resource: {
        insert: 'Resource <strong>{0}</strong> has been published.',
      },
      course: {
        insert: 'Course <strong>{0}</strong> has been published.',
      },
      message: {
        post: 'Announcement <strong>{0}</strong> has been published.',
      },
    },
  },
  iframePlaceholder: 'On production an iframe will be displayed here with src=',
  page: {
    announcements: {
      title: 'Announcements',
      newAnnouncement: 'New announcement',
      updateAnnouncement: 'Update announcement',
      published: 'Published',
      form: {
        title: 'New announcement',
        publish: 'Publish announcement',
        titlePlaceholder: 'Add title of the announcement',
        deleteConfirmation: 'Are you sure you want to remove the announcement {0}?',
      },
    },
    notifications: {
      title: 'Notifications',
      contentTitle: 'Your notifications',
    },
    settings: {
      changeProfileImage: 'Change Profile Image',
      accountSettings: 'Personal Profile',
      saveSettings: 'Save Settings',
      fullName: 'Full Name',
      changePassword: 'Change Password',
      currentPassword: 'Current Password',
      passwordMismatch: 'Password does not match retyped password',
      howToFeelSaveSuccess: 'You have successfully saved your personal emotion words!',
    },
    administration: {
      title: 'Administration',
      subtitle: 'Only administrators are able to edit various settings of the RULER platform!',
      emotionWords: {
        addEmotionWordButton: 'Add emotion word',
        emotionWordForm: {
          newTitle: 'Create new emotion word',
          editTitle: 'Edit emotion word',
          nameAlreadyExistsError: 'A word with the same name already exists.',
          deleteTitle: 'Delete emotion word',
          deleteConfirmation: 'Are you sure you want to remove the emotion word {0}?',
          wordIsUsed:
            "The emotion word can't be deleted because it's used in Mood Meter plots and/or in charters.",
          wordUsageDescription1:
            "Please select where the emotion word will be used. Selecting nothing won't displays the word to users.",
          wordUsageDescription2:
            'Used in Mood Meter allows the word to appear when users plot themselves via the Mood Meter.',
          wordUsageDescription3:
            'Used in Charter allows the word to appear when users choose emotion words for a charter. Either created by users or school-wide staff charters.',
        },
        tableHeaders: {
          name: 'Name',
          definition: 'Definition',
          moodMeter: 'Used in Mood Meter',
          energyMin: 'Energy min',
          energyMax: 'Energy max',
          pleasantnessMin: 'Pleasantness min',
          pleasantnessMax: 'Pleasantness max',
          charter: 'Used in Charter',
        },
      },
      jobTitles: {
        addJobTitleButton: 'Add job title',
        jobTitleForm: {
          newTitle: 'Create new job title',
          editTitle: 'Edit job title',
          nameAlreadyExistsError: 'Job title with the same name already exists.',
          deleteTitle: 'Delete job title',
          deleteConfirmation: 'Are you sure you want to remove the job title {0}?',
          titleIsUsed: "The job title can't be deleted because it's used.",
        },
        tableHeaders: {
          name: 'Job title',
        },
      },
      subjects: {
        addSubjectButton: 'Add subject',
        subjectForm: {
          newTitle: 'Create new subject',
          editTitle: 'Edit subject',
          nameAlreadyExistsError: 'Subject with the same name already exists.',
          deleteTitle: 'Delete subject',
          deleteConfirmation: 'Are you sure you want to remove the subject {0}?',
          titleIsUsed: "The subject can't be deleted because it's used.",
        },
        tableHeaders: {
          name: 'Subject',
        },
      },
      countries: {
        tableHeaders: {
          name: 'Country',
        },
        statusExplanation:
          'Only countries with "enabled" status will be available for selection when creating a district and school. Marking a country as "disabled" won\'t delete any district or school entries.',
      },
      states: {
        addStateButton: 'Add state',
        stateForm: {
          newTitle: 'Create new state',
          editTitle: 'Edit state',
          explanation: "States can't be deleted after they are created. They can be only renamed.",
        },
        tableHeaders: {
          name: 'State',
          abbr: 'Abbreviation',
        },
        noItems: 'No states in the selected country',
      },
      cities: {
        addCityButton: 'Add city',
        cityForm: {
          newTitle: 'Create new city',
          editTitle: 'Edit city',
          explanation: "Cities can't be deleted after they are created. They can be only renamed.",
        },
        tableHeaders: {
          name: 'City',
        },
        noItems: 'No cities in the selected country and state',
      },
    },
    roadmap: {
      headTitle: 'Roadmap',
    },
    showcase: {
      headTitle: 'Showcase',
      title: 'RULER Showcase',
      tabs: {
        school: 'ruler at your school',
        review: 'review showcases',
        community: 'the ruler community',
        explore: 'explore',
      },
    },
    tools: {
      rulerize: {
        selectStrategiesDescriptionText1: 'Select strategies to try out in the next 7 days.',
        selectStrategiesDescriptionText2: 'They will be added to {0}your list.{1}',
        filterDescription: 'Show me strategies for',
        subscribeBtn: 'I want to do this',
        peopleDoneIt: '{0} people at your school have done this',
        personDoneIt: '1 person at your school has done this',
        completeBtn: 'Complete',
        deleteBtn: 'Delete strategy',
        deleteTitle: 'Delete this strategy?',
        completed: 'Completed',
        signedUp: 'Signed up',
        expired: 'Expired',
        expires: 'Expires',
        findStrategies: 'Find Strategies',
        activate: 'Activate',
        reactivate: 'Reactivate',
        userChosenStrategies: "{0}'s Chosen Strategies",
        addedToList: 'Added to your list',
      },
      journal: {
        new: 'New Entry',
        all: 'All Entries',
        personal: 'Personal',
        fromCourses: 'From Courses',
        from: 'From',
        areYouSureToDeleteJournal: 'Are you sure you want to delete this journal entry?',
        form: {
          addEntry: 'Add an entry',
          editEntry: 'Edit entry',
          titlePlaceholder: 'Add Entry Title',
          entryPlaceholder: 'Add an entry...',
        },
      },
    },
    courseDetails: {
      notStarted: 'Not Started',
      assessmentTitle: 'Course Assessment',
    },
    courses: {
      title: 'Interactive Courses',
      subtitle:
        'Complete these two courses to develop a shared understanding of the power of emotions and provide you with the knowledge and tools for RULER implementation.',
      missing: 'No courses yet',
      released: 'Course Released',
      progress: 'Course Progress',
      score: 'Assessment Score',
      viewCourseButton: 'View Course',
    },
    modules: {
      review: 'Review',
    },
    moduleDetails: {
      ranOutOfTimeMessage: 'Oops, you ran out of time.',
      noTimeRemainingValue: 'Time is up!',
      timeLeftLabel: 'Time Left',
      activityTimeLabel: 'Activity Time',
      minutesLabel: 'minutes',
      saveToJournalButton: 'Save to Journal',
      savedToJournalButton: 'Saved to Journal',
      timeToRulerize: 'Time to RULERize',
      rulerizeCallout: {
        thankYouText: 'Thank you for committing to these strategies!',
        trackStrategiesText: 'You can track all your strategies with the',
        rulerizeToolText: 'RULERize tool',
      },
      outro: {
        nextModuleButton: 'Go to next module',
        assessmentHeading: 'Test your knowledge - take the course assessment',
        assessmentButton: 'Go to Assessment',
      },
    },
    assessment: {
      checkAnswersButton: 'Check Answers',
      startOverButton: 'Start Over',
      congratualtionsText: 'Congratulations! You got',
      question: 'Question',
      correctAnswerLabel: 'The correct answer is',
      noAnswerProvided: 'No answer provided',
      correctAnswer: 'Correct Answer',
      incorrectAnswer: 'Incorrect Answer',
      yourScore: 'Your Score:',
      testYourKnowledge: 'Test your knowledge',
    },
    moodMeter: {
      title: 'Mood Meter',
      subtitle1:
        'The Mood Meter enhances self- and social awareness and supports the development of a nuanced emotion vocabulary and a range of strategies for regulating emotion.',
      subtitle2:
        'You can plot your current emotion or the emotions of a group by clicking the “New Plot” button below.',
      yourPersonalPlots: 'Your Personal Plots',
      yourGroupPlots: 'Your Group Plots',
      sharedGroupPlots: 'Shared Group Plots',
      newPlotButton: 'New Plot',
      plotYourselfButton: 'Plot yourself',
      plotYourMood: 'Plot your mood',
      plotOpened: 'Plot opened',
      plotAGroupButton: 'Plot a group',
      plotYourself: 'Plot yourself',
      plotGroup: 'Plot a group',
      nextButtonLabel: 'Next',
      backButtonLabeL: 'Back',
      selectExistingGroup: 'Select an existing group',
      addNewGroup: 'Add new group',
      startGroupMoodMeter: 'Start group Mood Meter',
      giveYourGroupAName: 'Give your group a name',
      selectAGroupType: 'Select a group type',
      groupNamePlaceholder: 'Group Name',
      shareGroupLink: 'Share the plot link with your group',
      shareGroupLinkSubtitle:
        'Or ask the group participants to visit {0} and enter the group plot code {1}',
      peopleParticipated: 'people participated',
      personParticipated: 'person participated',
      peopleHavePlottedTheirMood: 'people have plotted their mood',
      personHasPlottedTheirMood: 'person has plotted their mood',
      reopenButton: 'Reopen Plot for 5 minutes',
      closeButton: 'Stop and view results',
      notStarted: 'This group plot will start on {0} and will have a duration of {1}',
      notStartedShort: 'Not started',
      remaining: 'remaining',
      isClosed: 'This group is closed and cannot be reopened.',
      areYouSureToDeleteMood: 'Are you sure you want to delete this mood?',
      showOnlyPlotsFor: 'Show only plots for',
      allGroups: 'All Groups',
      filterHeading: 'You have recorded a total of',
      plots: 'plots',
      showYourPlotsFor: 'Show your plots for',
      youSuccessfullyPlottedYourself:
        "Congratulations! You've successfully completed this mood meter.",
      thankYouForParticipating: 'Thank you for participating.',
      willShareResults: 'The group owner will share results with your group.',
      ifSharingDevice:
        'If you are sharing your device with other users, click or tap {0}New Plot{1} and hand over the device.',
      plotSaved: 'Your plot has been saved',
      allTime: 'All time',
      last7Days: 'Last 7 Days',
      last30Days: 'Last 30 Days',
      filterButton: 'Filter',
      emptyGroupsMessage: 'Once you create groups, they will be listed here.',
      shared: 'shared',
      groupNotAvailable: 'Group not available',
      expired: 'Expired',
      closed: 'This Mood Meter plot is now closed.',
      newPlot: 'New plot',
      groupPlot: 'Mood Meter Group Plot',
      typeGroupCode: 'Type your group plot code below',
      invalidCode: 'Group mood meter code "{0}" is not valid.',
      sharedInTitle: 'Who should be able to see the results after the plot is complete?',
      plotScheduleTitle: 'The plot will start',
      immediatePlot: 'Immediately',
      laterPlot: 'Or schedule for a future time',
      selectDate: 'Click to select date',
      plotDurationTitle: 'The plot will be open for',
      lastActiveOn: 'Last active on ',
      scheduledFor: 'Scheduled for ',
      removeGroupPlot: 'Are you sure you want to remove group plot from group {0}?',
      sharedInOptions: {
        personal: 'Only me',
        school: 'Everyone in my school',
      },
      orders: {
        groupZtoA: 'Group Z - A',
        groupAtoZ: 'Group A - Z',
      },
      step: {
        pleasantness: {
          title: 'Rate your degree of pleasantness',
          subtitle:
            'Pay attention to what is going on through your mind at this moment. Do you perceive your current state as pleasant or unpleasant? And how pleasant or unpleasant, compared to your own life experience?',
          minScale: 'Unpleasant',
          maxScale: 'Pleasant',
        },
        energy: {
          title: 'Rate your energy level',
          subtitle:
            'What is your level of activation? Pay attention to what is going on in your body.',
          minScale: 'Low Energy',
          maxScale: 'High Energy',
        },
        emotion: {
          title: 'Plot Yourself',
          selectEmotionWord: 'Select an emotion word that best describes how you are feeling',
          selectEmotionWordErr: 'Please select an emotion word.',
          describeReason: "In a few words, describe why you're feeling this way.",
          showMoreEmotionWords: 'Show more',
          showLessEmotionWords: 'Show less',
        },
      },
    },
    resources: {
      title: 'Resource Library',
      favorites: 'Favorites',
      addToFavorites: 'Add To Favorites',
      removeFromFavorites: 'Remove From Favorites',
      glossary: 'Glossary',
      collections: 'Collections',
      resourcesCount: 'resources',
      resourceCount: 'resource',
      searchResources: 'Search Resources',
      new: 'new',
      favsText: '{0} people at your school have added to their favorites.',
      favsTextPerson: '1 person at your school has added to their favorites.',
      print: 'Print',
      furtherLearningLinks: 'Further Learning (Links)',
      suggestedTime: 'Suggested Time',
      glossaryTab: {
        keywordCard: 'Keyword Card',
        didYouKnow: 'Did you know?',
        noMatches: 'No glossaries starting with this letter',
      },
    },
    charter: {
      staffCharter: {
        title: 'Your Staff Charter',
        placeholder: "This is where your school's charter will appear once published.",
        noStaffCharterMessage: "Your school's staff charter has not been published yet.",
        contributionStatistics: 'Staff Charter Contribution Statistics',
        contributeToStaffCharter: "Contribute to your school's Staff Charter",
        topEmotionWords: 'Top 10 Emotion Word Contributions',
        emotionWordsFilters: 'Emotion words filters',
        tooLongForPrint1: 'The charter might be too long to print on one page.',
        tooLongForPrint2: 'Please use the filters to select fewer words.',
        lastSelectedWordTooltip: 'At least one emotion word needs to be selected.',
        placeholderForEmptyContent: 'Your School Charter will appear here when it is published.',
        wordCloud: {
          title: 'Word Cloud',
          noContributions: 'No contributions',
        },
        barChart: {
          title: 'Bar Graph',
          noContributions: 'No contributions',
        },
        survey: {
          form: {
            activeFrom: 'Active from',
            activeTo: 'to',
            duration: 'Duration',
            ratesHint:
              'Please take a moment to consider the last three months at our school. Then, answer the following questions about your feelings and experiences. Your answers are confidential and important to us because we want to create the best possible climate for our faculty and students.',
            rates: {
              1: 'Not all all',
              2: 'A little',
              3: 'Somewhat',
              4: 'Frequently',
              5: 'Daily',
            },
            ratingValidationError: 'Please rate this emotion word.',
          },
          resultInfo: {
            lockedMessage: 'Staff Charter is locked until the active survey ends on',
            viewReportsButton: 'View report',
          },
          manage: {
            noActiveSurvey: 'Currently there is no active staff charter survey.',
            createSurvey: 'You can start creating one now by clicking the {0} button.',
            noCharter:
              'Currently there is no published staff charter in the school. In order to create a survey for a staff charter, one needs to be published. You can publish a staff charter by going to the {0} tab.',
            activeSurvey1: 'There is an active staff charter survey until ',
            activeSurvey2: '. All users in the school are able to submit a staff charter survey.',
            activeSurvey3: 'Only one active survey is allowed at a time.',
            surveyCreated: 'Survey created',
            createdBy: ' by ',
            createdOn: ' on ',
            listItemActive: ' and is active until ',
            noSurveyChoices: 'No one has rated this survey question.',
            noUsersHaveSubmitted:
              'No one has submitted this survey. When there are survey submissions the statistics of the ratings will appear below.',
            personSubmitted: 'One person has submitted this survey.',
            peopleSubmitted: ' people have submitted this survey.',
          },
          closePreviewButton: 'Close Preview',
          createButton: 'Create Survey',
          publishButton: 'Publish Survey',
          submitButton: 'Submit Survey',
          closeButton: 'Close Survey',
          takeSurveyButton: 'Fill out the Survey',
        },
      },
      newCharter: 'New Charter',
      newCharterButton: 'Create New Charter',
      tabStaffCharter: 'Staff Charter',
      tabManageStaffCharterSurvey: 'Manage Surveys',
      tabSubmitStaffCharterSurvey: 'Staff Charter Survey',
      tabStaffCharterContributions: "Contribute to your school's Staff Charter",
      tabAboutStaffCharter: 'About the Charter',
      tabEditStaffCharter: "Manage School's Staff Charter",
      tabYourCharters: 'Your Charters',
      uploadExistingCharterButton: 'Upload Existing Charter',
      type: 'Type:',
      charterCreationMeeting: 'Charter Creation Meeting',
      wantToFeel: 'We want to feel',
      inOrderToFeel: 'In order to feel',
      weWill: 'we will',
      inOrderToFeelThisEmotion: 'In order to feel {0}, we will {1}.',
      inOrderToHaveTheseFeelings: 'In order to have these feelings consistently, we will',
      poweredBy: 'Powered by RULER',
      createdOnBy: 'Created on {0} by {1}',
      deleteCharter: 'Are you you want to remove charter {0}?',
      viewPdf: 'View PDF',
      loadingPdf: 'Loading...',
      generatePdf: 'Generate PDF',
      downloadPdf: 'Download PDF',
      contributeSuccessMessage: 'You have successfully contributed to the staff charter!',
      noChartersMessage:
        "Before you create your first Charter, if you haven't already, we recommend starting with the",
      charterModuleLinkText: 'Charter course module.',
      orders: {
        titleAtoZ: 'Title A - Z',
        titleZtoA: 'Title Z - A',
      },
      viewCharterButton: 'View School Staff Charter',
      modal: {
        existingCharterTitle: 'Upload a Charter',
        typeLabel: 'What type of Charter are you creating?',
        titleLabel: 'Give your Charter a name:',
        getStartedTitle: "Let's get started",
        titleInstructions: `Hint: Pick something that will help you find it later. For example, “Cline Elementary
        Staff Charter” or “Grade 7 Student Charter.”`,
        emotionWordsTitle: 'How do we want to feel?',
        emotionWordsInstructions: `Consider how you want to feel in school. What specific emotions would you like to
        experience each day or as often as possible in the school? Some feeling words that you
        generate may have similar meanings. Group those words of similar meaning together, and
        choose the distinct words that best represent the various feelings.`,
        wantToFeeltext1: 'As a ',
        wantToFeeltext2: ', we want to feel...',
        emotionWordsLimit: 'Enter up to {0} words',
        emotionWordsRemaining: 'You can add up to {0} words',
        contentTitle: 'How will we help each other to experience these feelings?',
        contentInstructions: `Think critically about the specific, observable, and measurable behaviors that everyone
        can do to help each other feel how they said they want to feel. There may be some
        overlap across feelings, such that the same behavior may contribute to more than one
        feeling. Work together to identify one to two unique behaviors for each feeling.`,
        inOrderToFeelText1: 'In order to feel ',
        inOrderToFeelText2: ', we will...',
        templateTitle: 'You are almost finished!',
        templateLabel: 'Choose a design template for your Charter',
        previewTitle: 'Your Charter is ready!',
        editingCharterTitle: 'Editing Charter:',
      },
      approveCharter: {
        title: 'View your staff word and behavior suggestions, or add your own...',
        updateButton: 'Update staff charter',
        emotionWordsTitle: 'Staff Emotion Word Suggestions',
        emotionWordsInstructions: 'You can view the emotion words contributed by your staff below.',
        actionsTitle: 'Staff Charter Behavior Suggestions',
        actionsInstructions: 'You can view the behaviors contributed by your staff below.',
        addToCharter: 'Add to charter',
        suggestedByOne: 'Suggested 1 time',
        suggestedByMany: 'Suggested {0} times',
        successMessage: 'You have successfully updated the staff charter!',
        addContributionsInstructions:
          'You can also add commonly suggested words and behaviors by expanding your contributions form.',
        contributionsFormButton: 'Your contributions form',
        activeSurvey:
          'There is an active staff charter survey. Editing of a staff charter is disabled while a survey is ongoing. For more information on the ongoing survey you can visit Manage Surveys tab.',
      },
    },
    curriculum: {
      gettingStarted: 'Getting Started',
      viewUnits: 'View Units',
      unit: 'Unit',
      lesson: 'Lesson',
      lastLeftOn: 'You last left off on',
      allUnits: 'All Units',
      overview: 'Overview',
      objectives: 'Objectives',
      duration: 'Duration',
      materials: 'Materials',
      selStandards: 'SEL Standards',
      sessionGuide: 'Session Guide',
      downloadSessionGuide: 'Download session guide',
      keyTerms: 'Key Terms',
      goToNextLesson: 'Go to next lesson',
    },
  },
  activationModal: {
    activateDistrictTitle: 'Activate District',
    deactivateDistrictTitle: 'Deactivate District',
    activateSchoolTitle: 'Activate School',
    deactivateSchoolTitle: 'Deactivate School',
    activateCountryTitle: 'Activate Country',
    deactivateCountryTitle: 'Deactivate Country',
    activateText: 'Are you sure you want to activate ',
    deactivateText: 'Are you sure you want to deactivate ',
    activateBtn: 'Activate',
    deactivateBtn: 'Deactivate',
    active: 'Active',
    inactive: 'Inactive',
    districtStatus: 'District Status',
    schoolStatus: 'School Status',
  },
  dashboard: {
    name: 'Dashboard',
    welcome: 'Welcome',
    subtitle: 'This RULER dashboard is just for you.',
    bookmarks: {
      districtsTitle: 'Bookmarked Districts',
      bookmarkDistrictsAction: 'Bookmark districts',
      schoolsTitle: 'Bookmarked Schools',
      bookmarkSchoolsAction: 'Bookmark schools',
      bookmarkExplanation: ' that you often work with to see them on this screen.',
    },
    visitCharterTool: 'Visit Charter Tool',
    addUserButton: 'Add User',
    addUsersButton: 'Add Users',
    downloadActivationLinksButton: 'Activation links CSV',
    sortBy: 'Sort By',
    alphabetical: 'Alphabetical',
    dateAdded: 'Date Added',
    takeoffChecklist: 'Your RULER Online Take-off Checklist',
    howToFeel: {
      headline: 'How I want to feel at school',
      inOrderToFeel: 'In order to feel',
      inOrderToFeelWeWill: 'we will',
      pleaseSelect: 'Please select',
    },
    numberOfSchools: 'Schools: {0} (Active: {1}, Deactivated: {2})',
    invitedUsers: 'Invited Users: {0}',
    activeUsers: 'Active Users: {0}',
    invitedStaff: 'Invited Staff: {0}',
    activeStaff: 'Active Staff: {0}',
    invitedTeacherStaff: 'Invited Teachers/Staff: {0}',
    activeTeacherStaff: 'Active Teachers/Staff: {0}',
    schools: {
      manageText: 'Manage Schools',
      addSchoolButton: 'Add School',
      code: 'School Code',
      invited: 'Invited Teachers/Staff',
      active: 'Active Users',
    },
    userForm: {
      title: 'Add People to',
      editTitle: 'Edit User',
      resend: 'Resend',
      resendInvitation: 'Resend Invitation',
      showActivationLink: 'Show Invitation link',
      showResetPasswordLink: 'Show Reset Password link',
      invalidEmail: 'Please enter a valid email address.',
      existingUserError:
        'The email address you entered is already registered. Please choose another email address.',
      fullNameError: 'Please enter user full name.',
      removeUser: 'Are you sure you want to remove user {0}?',
      resendInvitationConfirmation: 'Are you sure you want to resend an invitation email to {0}',
      activationLink: 'Activation link for user {0}',
      activationLinkInstructions:
        'Please copy the link below and send it to the appropriate user. By visiting the link the user will be activating their account.',
      resetPasswordLink: 'Reset Password link for user {0}',
      resetPasswordLinkInstructions:
        'Please copy the link below and send it to the appropriate user. By visiting the link the user will be resetting their password.',
      addMultipleUsers: 'Add Multiple Users',
      addMoreForms: 'Add 5 more forms',
      uploadCsv: {
        title: 'Add people with CSV file upload',
        schoolCreationSuccessful: 'The school has been created successfully.',
        uploadUsersInstructions:
          "You have the option to add users. Don't worry you can still add users later from the school's page.",
        discard: 'Discard',
        selectFile: 'Select file',
        replaceFile: 'Replace file',
        confirmUpload: 'Confirm Upload',
        downloadSample: 'Download Sample CSV',
        button: 'Upload CSV',
        headers: {
          name: 'Name',
          email: 'Email',
          role: 'RULER Online role',
          personType: 'School role',
        },
        duplicateError: 'Selection contains already registered users.',
        noUsersSelectedError: 'Please select at least one user to upload.',
        description: 'Upload a CSV file with the following structure:',
      },
    },
    locationForm: {
      addressTitle: 'District Address',
      websiteLabel: 'Website URL',
      phoneNumberLabel: 'Phone Number',
      addressLabel: 'Street Address',
      address2Label: 'Street Address 2 ',
      cityLabel: 'City',
      stateLabel: 'State/Province',
      countryLabel: 'Country',
      postalCodeLabel: 'Zip/Postal Code',
    },
    manageSchoolForm: {
      addTitle: 'Add New School',
      updateTitle: 'Update School',
      schoolNameLabel: 'School Name',
      districtNameLabel: 'School District',
      gradesLabel: 'Grades at this school',
      addressTitle: 'School Address',
      implementationPhase: 'School RULER Implementation Phase',
      schoolDistrict: 'School District',
      schoolRulerStart: 'Please select the year RULER was started at your organization.',
      totalStaff: 'Please select how many people are part of your organization.',
      isRulerSchool: 'Is this school already a RULER school?',
      phase: 'Phase',
      uploadLogo: 'Upload School Logo',
      changeLogo: 'Change School Logo',
      offlineTrainings: 'Please list the trainings that your school has attended to:',
      selectTraining: 'Please select training',
      selectTrainingDate: 'Please select training date',
      trainingAttendedAt: 'Date of attendance:',
      addTraining: 'Add training',
      coachName: 'Coach Name',
      coachEmail: 'Coach Email',
    },
    moveUserForm: {
      schoolNameLabel: 'School',
      districtNameLabel: 'District',
      moveUserHeader: 'Move user {0} to:',
      noSchoolsInDistrict: 'No schools in the selected district',
      moveToSchoolConsent: 'Move this district user to a school',
      moveToDistrictConsent: 'Move this school user to a district',
      moveToSchoolInstructions:
        'By moving this user to a school, they will be converted to a {0} RULER Online role and a {1} School role. Their role can be changed later via the user edit action.',
      moveToDistrictInstructions:
        'By moving this user to a district, they will be converted to a {0} RULER Online role and a {1} District role. Their role can be changed later via the user edit action.',
    },
    ycei: {
      menuItem: 'YCEI Team',
      manageText: 'YCEI Team',
      users: 'YCEI Users',
      filters: {
        all: 'All RULER Online Users',
        implementationTeam: 'All Implementation Team Users',
        schoolLeadership: 'All Leadership Users',
        schoolTeachers: 'All School Teacher Users',
      },
    },
    filters: {
      filterByState: 'Filter by State/Province',
      filterByCountry: 'Filter by Country',
      filterByDistrict: 'Filter by District',
      status: {
        enabled: 'Active',
        onboarding: 'Onboarding',
        disabled: 'Invited',
      },
    },
    districts: {
      code: 'District Code',
      manageText: 'Districts you manage',
      addDistrictButton: 'Add District',
      downloadRulerOnlineMemberList: 'Download RULER Online Member List',
      users: 'District Office Staff',
      schools: 'District Schools',
      joined: 'Joined',
      numberOfDistricts: 'Districts: {0}',
    },
    schoolDetails: {
      notFound: 'School not found',
      manageUsers: 'Manage Users',
      implementationTeam: 'Implementation team',
      schoolStatisticsDescription: `As your school implements the RULER Approach, visit this page to track how your community members are using RULER Online. Check back for anonymous data on commonly used resources, top feeling words, and other usage statistics`,
      howWeWantToFeel: {
        navTitle: 'How We Want to Feel',
        wordCloud: {
          title: 'Word Cloud',
          noContributions: 'No contributions',
        },
        barChart: {
          title: 'Bar Graph',
          noContributions: 'No contributions',
        },
      },
      tabStaffCharter: 'Staff Charter',
      noEmotionWordsMessage: 'There are no emotion words for the selected period.',
      topEmotionWords: 'Top Emotion words',
      registeredUsers: 'registered users',
      statsForLabel: 'Show stats for',
      today: 'Today',
      '7days': '7 days',
      '30days': '30 days',
      '90days': '90 days',
      custom: 'Custom',
      dateRange: 'Select date range',
      roadmapStatistics: {
        navTitle: 'Roadmap',
        title: 'Roadmap Statistics',
        noStatistics: 'There are no Roadmap statistics in the selected period.',
        columns: {
          title: 'Title',
          people: 'Registered people in your school',
          peopleCompleted: 'People in your school who completed',
        },
      },
      courseStatistics: {
        beginning: 'Beginning',
        have: 'have',
        navTitle: 'Courses',
        moduleCompletionTitle: 'Module completion',
        assessmentCompletionTitle: 'Assessment completion',
        moduleCompletionLabel: '{0} {1} out of {2} total {3} has completed {4}',
        registeredUsersWhoHaveCompleted: '% who have completed',
        assessmentCompletionLabel: '{0} {1} out of {2} total {3} {4} completed the assessment',
        assessmentNotCompletionLabel:
          '{0} {1} out of {2} total {3} {4} not completed the assessment',
        whoHaveTakenAssessment: '% who have taken assessment',
        whoHaveYetToTakeAssessment: '% who have yet to take assessment',
      },
      toolsStatistics: {
        navTitle: 'Tools',
        noMoodMeterPlots: 'There are no mood meter plots for users in the selected period.',
        trendingEmotionWordsTitle: 'Top trending emotion words in all plots (personal and group)',
        journalPostsTitle: 'Journal posts by type',
        rulerizeCommitmentsTitle: 'RULERize Strategies',
        activeRulerizeCommitments: 'Active',
        completedRulerizeCommitments: 'Completed',
        noRulerizeCommitments:
          'There are no RULERize strategies from users in the selected period.',
        noJournalPosts: 'There are no journal posts from users in the selected period.',
        journalPostsLabel: 'Journal posts',
        rulerizeCommitmentsLabel: 'RULERize Commitments',
        personalCheckInsSubtitle: 'Individual check-ins by Quadrant',
        noPersonalCheckInsMessage:
          'There are no individual mood meter check-ins in the selected period.',
        groupCheckInsSubtitle: 'Group check-ins by Quadrant',
        noGroupCheckInsMessage: 'There are no group mood meter check-ins in the selected period.',
        checkIn: 'check-in',
        personalCheckInsChartLabel: 'Personal check-ins',
        emotionWordsChartLabel: 'emotion words',
        user: 'user',
        group: 'group',
        charter: 'charter',
        post: 'post',
        strategy: 'strategy',
        rulerizeCommitmentsTooltip: '{0} {1} {2} by {3} teacher/staff',
        journalPostsTooltip: '{0} {1} by {2} teacher/staff',
        individualCheckInsMessage: '{0} individual {1} saved by {2} {3}.',
        groupCheckInsMessage: '{0} {1} in {2} {3}.',
        noChartersMessage: 'There are no charters created in the selected period.',
        totalChartersCreated: '{0} total {1} created', // 3 total charters created
      },
      resourceStatistics: {
        navTitle: 'Resources',
        title: 'Top Resources',
        noStatistics: 'There are no statistics for resources in the selected period.',
        downloadCsv: 'Download CSV',
        columns: {
          title: 'Title',
          collections: 'Collections',
          type: 'Type',
          visited: 'Visited',
          favorite: 'Favorite',
        },
      },
    },
    districtDetails: {
      notFound: 'District not found',
      addSchoolButton: 'Add School to District',
    },
    yceiUserForm: {
      mobilePhoneNumber: 'Mobile Number',
      officePhoneNumber: 'Office Phone Number',
      rulerOnlineRole: 'YCEI role:',
      rulerOnlinePersonType: 'YCEI Label:',
    },
    districtUserForm: {
      mobilePhoneNumber: 'Mobile Number',
      officePhoneNumber: 'Office Phone Number',
      rulerOnlineRole: 'RULER Online role:',
      rulerOnlinePersonType: 'District role:',
    },
    schoolUserForm: {
      rulerOnlineRole: 'RULER Online role:',
      rulerOnlinePersonType: 'School role:',
    },
    userActivationData: {
      title: 'User {0} has been created.',
      text: 'An invitation email was sent to the email address provided. Thank you.',
      multipleTitle: 'Users have been created.',
      multipleText: 'Invitation emails were sent to the email addresses provided. Thank you.',
    },
    districtForm: {
      addTitle: 'Add New District',
      updateTitle: 'Update District',
      districtNameLabel: 'District Name',
      districtNameHelp: {
        text1: 'Important:',
        text2:
          ' Please enter full district name. Example: "New Haven Unified School District" as opposed to "NH Unified"',
      },
      isDistrictQuesion: 'Is this district a RULER district?',
      districtRulerStart: 'Please select the year RULER was started at your organization.',
      coachName: 'Coach Name',
      coachEmail: 'Coach Email',
    },
    cell: {
      course: {
        text1: 'Wondering what RULER is and what it does?',
        text2: 'Then the ',
        text3: 'Introduction to Emotions and Emotional Intelligence',
        text4: ' is the mini-course for you.',
        button: 'Start Now',
      },
      tools: {
        text1: 'As you make your way through the courses, you will learn how to use an array of ',
        text2: 'Tools',
        text3: ' available to you.',
        button: 'See Tools',
      },
      resourceLibrary: {
        text1: 'The',
        text2: 'Resource Library',
        text3: `features a growing set of resources to support your learning and implementation.`,
        button: 'Explore',
      },
    },
    cards: {
      reward: {
        title: 'Hey, Early Adopter!',
        text: 'We are so glad you are here! How many colleagues can you get to sign on?',
      },
    },
    nudges: {
      roadmap: {
        visitRoadmap: 'Visit Roadmap',
      },
      navMenuItem: 'Roadmap',
      oneTaskToComplete: '1 task left to complete',
      tasksToComplete: '{0} tasks left to complete',
      oneTaskToCompleteInSchool: '1 task left to complete in school',
      tasksToCompleteInSchool: '{0} tasks left to complete in school',
      peopleCompleted: '{0} people completed',
      personCompleted: '{0} person completed',
      everybodyCompleted: 'Completed by everyone',
      allCompleted: 'All tasks completed',
      completed: 'Completed',
      markCompleted: 'Mark Completed',
      publish: 'Publish Tasks',
      published: 'Published',
      unpublish: 'Unpublish',
      completedNote:
        'Note: You might have already completed some if not all of these tasks before/during the RULER Institute training.',
      confirmAssignmentHeader: 'Confirm milestone assignment',
      confirmPublishButton: 'Yes, publish now',
      confirmUnpublishButton: 'Unpublish milestone',
      cancelAssignmentButton: 'Maybe later',
      publishText:
        'You are about to publish the tasks in this milestone for all registered users in your school to view and mark as completed.',
      unpublishText:
        'You are about to unpublish the tasks in this milestone. Historical entries will be kept.',
    },
  },
  auth: {
    activationText: 'Please select a password to activate your account',
    checkYourEmail: 'Check your e-mail',
    checkYourEmailSubtitle: 'Please check your email for instructions to reset your password.',
    resetPassword: 'Reset password',
    resetYourPassword: 'Reset your password',
    forgottenPassword: 'Forgotten password',
    resetYourForgottenPassword: 'Reset your forgotten password',
    sendResetLink: 'Send reset link',
    passwordResetSuccessful: 'Password reset successful',
    missingResetToken: "You're missing a reset token.",
    missingActivationToken: "You're missing an activation token.",
    login: {
      title: 'Log in to your account',
      invalidEmailPassword: 'The email address or password you entered is incorrect.',
      schoolDeactivatedError:
        'Your school’s access to RULER Online has expired. To renew your RULER subscription, please reach out to your school or district administrator to complete <a target="_blank" rel="noopener noreferrer" href="https://yale-ycei.my.salesforce-sites.com/">this application</a>. Please contact <a target="_blank" rel="noopener noreferrer" href="mailto:rulersubscription@yale.edu">rulersubscription@yale.edu</a> if you require further support.',
      districtDeactivatedError:
        'Your district’s access to RULER Online has expired. To renew your RULER subscription, please reach out to your school or district administrator to complete <a target="_blank" rel="noopener noreferrer" href="https://yale-ycei.my.salesforce-sites.com/">this application</a>. Please contact <a target="_blank" rel="noopener noreferrer" href="mailto:rulersubscription@yale.edu">rulersubscription@yale.edu</a> if you require further support.',
    },
    register: {
      callToAction: 'Already have an account?',
      callToActionSubtitle1:
        'If you are not sure whether your school has started implementing RULER, please reach out to a school administrator.',
      callToActionSubtitle2:
        'If your school does have a RULER implementation team, they should be able to provide you with information to sign in and complete your registration.',
      submitButton: 'Create Account',
      accept: 'By signing up, you agree to the',
      and: 'and',
      fullNameError: 'Please enter your full name.',
      invalidEmail: 'Please enter a valid email address.',
      existingUserError:
        'The email address you entered is already registered. Please choose another email address.',
    },
    logIn: 'Log In',
    emailLabel: 'E-mail',
    password: 'Password',
    retypePassword: 'Retype password',
    shownPassword: 'Show Password',
    forgotPassword: 'Forgot Password',
  },
  showcase: {
    like: 'Like',
    publish: 'Publish',
    published: 'Published',
    unpublish: 'Unpublish',
    promote: 'Promote',
    promoteDropdownIteam: 'Promote to RULER Community',
    unpublished: 'Unpublished',
    yourDashboard: 'your dashboard',
    modal: {
      newTitle: 'Create new showcase post',
      addButton: 'Add to showcase',
      titleFieldLabel: 'Add title',
      bodyFieldLabel: 'Add description (256 characters max)',
      authorFieldLabel: 'Author',
      selectFile: 'Select image or video file',
      promoteTitle: 'Promote showcase post to RULER Community',
      promoteBodyFieldLabel: 'Description',
      promote: 'Promote',
      postDoesNotExist: 'This post does not exist or is not accessible to you',
    },
    filters: {
      selectSchool: 'Select School',
      selectDistrict: 'Select District',
      selectStatus: 'Select Status',
      searchTag: 'Search for a tag',
    },
    noResultsByTag: 'No showcases found for tag <strong>{0}</strong>.',
  },
  onboarding: {
    agreementText: 'I agree to the RULER Online',
    termsAndConditions: 'Terms and Conditions',
    getStarted: "Let's get started",
    gradesQuestion: 'Which grades do you teach?',
    jobTitleQuestion: 'What is your job title?',
    subjectsQuestion: 'What subjects do you teach?',
    emotionsQuestionDistrict: 'How do you want to feel at your district?',
    emotionsQuestionSchool: 'How do you want to feel at your school?',
    continueToDashboard: 'Continue to Your Dashboard',
    selectGrades: 'Please select the grades you teach.',
  },
  userRoles: {
    ruler_ruler_admin: 'Admin',
    ruler_ycei_admin: 'YCEI Admin',
    ruler_ycei_member: 'YCEI Member',
    ruler_school_member: 'School Staff',
    ruler_school_admin: 'School Admin',
    ruler_school_implementation_team: 'School Implementation Team',
    ruler_district_member: 'District Staff',
    ruler_district_admin: 'District Admin',
    ruler_district_implementation_team: 'District Implementation Team',
    ruler_content_manager: 'Content Manager',
  },
  shortUserRoles: {
    admin: 'Admin',
    implementationTeam: 'Implementation Team',
    staff: 'Staff',
    member: 'Member',
  },
  personTypes: {
    teacher: 'Teacher',
    staff: 'Staff',
    leadership: 'Leadership',
    ycei_leadership: 'YCEI Leadership',
    ycei_coach: 'YCEI Coach',
    ycei_customer_support: 'YCEI Customer Support',
    ycei_research: 'YCEI Research',
  },
  shortPersonTypes: {
    teacher: 'Teachers',
    staff: 'Staff',
    leadership: 'Leadership',
    ycei_leadership: 'Leadership',
    ycei_coach: 'Coach',
    ycei_customer_support: 'Customer Support',
    ycei_research: 'Research',
  },
  validator: {
    email: 'This email is invalid.',
    existingEmail: 'Email is already registered.',
    nonEmpty: 'This field is required.',
    alphanumeric: 'This field must contain only letters and numbers',
    url: 'This field must be a valid url',
    number: 'This field must be a valid number',
    date: 'This field must be a valid date',
    phoneNumber: 'This field must be a valid phone number',
    postalCode: 'This field must be a valid postal code',
    length: 'This field must not be longer than 160 characters',
    length256: 'This field must not be longer than 256 characters',
    lengthMin8: 'This field must not be shorter than 8 characters',
    invalidWebsite: 'Please enter a valid URL',
  },
  errors: {
    formErrorsHint: 'Please resolve the errors above, before continuing',
    not_found: {
      title: 'Error 404 - Page Not Found',
      description: 'The page you were looking for was not found.',
    },
    exception: {
      title: 'Oops! Something went wrong.',
      description1: 'Please try reloading this page.',
      description2:
        'We would appreciate it if you take a moment to provide feedback so that we can fix the error faster.',
      reloadButton: 'Reload',
      report: 'Feedback',
      reportTitle: 'Error Report',
      reportSubmitButton: 'Submit Report',
    },
    invalidTokenInActivation: 'This activation link has expired!',
    invalidTokenContactTeam:
      'Please contact your school or district administrator or the RULER Team to request a new invitation.',
  },
  maintenancePage: {
    maintenance: 'Maintenance',
    greeting: 'Hello!',
    line1: 'We are taking a Meta-Moment.',
    line2:
      'Our site is momentarily down for maintenance and updates, so it can live up to its best self.',
    line3: 'Please check back shortly.',
  },
  unhandledServerError: 'Sorry! Unhandled Server error.',
  connectivityError:
    'Please make sure your internet connection is reliable and try again a bit later. Thank you.',
  curriculum: 'Curricula',
  bookmarkButton: {
    tooltipWhenInactive: 'Bookmarking will display it in your dashboard',
    tooltipWhenActive: 'Removing the bookmark will remove it from your dashboard',
  },
  autocomplete: {
    placeholders: {
      state: 'Select state',
      country: 'Select country',
    },
  },
}
export default en
